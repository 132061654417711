import React from "react";
import H from "next/head";

/**
 * Head component for title.
 * @param {Object} props
 */
const Head = ({ title = "Saweria", children }) => {
  return (
    <H>
      <meta charSet="UTF-8" />
      <title>{`${title} | Saweria`}</title>
      {children}
    </H>
  );
};
export default Head;
