import React from "react";
import { Link as L } from "@chakra-ui/react";
import NextLink from "next/link";

const Link = React.forwardRef(
  ({ children, textDecoration = "none", href, linkAs, ...props }, ref) => {
    return (
      <NextLink href={href} passHref ref={ref} as={linkAs}>
        <L
          as="a"
          textDecoration={textDecoration}
          _hover={{
            textDecoration: "none",
          }}
          {...props}
        >
          {children}
        </L>
      </NextLink>
    );
  }
);
export default Link;
