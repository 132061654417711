import React from "react";
import Link from "next/link";
import { Heading, Box, Flex } from "@chakra-ui/react";

import Image from "next/image";
import { DefaultCharacter } from "../assets";

const Header1 = () => {
  return (
    <Flex
      as="button"
      data-testid="header"
      p={2}
      marginTop="3"
      marginBottom="5"
      zIndex="1"
      direction="column"
      alignItems="center"
    >
      <Flex w="50%">
        <Image src={DefaultCharacter} alt="Saweria Logo" />
      </Flex>
      <Link href={"/"}>
        <Heading
          as="h1"
          fontSize={["4xl", "5xl"]}
          textTransform="none"
          p="0"
          m="0"
          color="blackish"
          fontWeight="light"
          textDecoration="none"
        >
          saweria.co
        </Heading>
      </Link>
    </Flex>
  );
};

const Header2 = (props) => {
  return (
    <Flex direction="column" as="a" {...props} alignItems="center">
      <Flex w="50%">
        <Image src={DefaultCharacter} alt="Saweria Logo" />
      </Flex>
      <Heading
        as="h1"
        fontSize={["4xl", "5xl"]}
        textTransform="none"
        p="0"
        m="0"
        color="blackish"
        fontWeight="light"
        textDecoration="none"
      >
        saweria.co
      </Heading>
    </Flex>
  );
};

export { Header1, Header2 };
