import React from "react";
import { Box } from "@chakra-ui/react";

const Dialog = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <Box
      p={3}
      border="default"
      boxShadow="raised"
      ref={ref}
      borderRadius="md"
      backgroundColor="whitish"
      {...props}
    >
      {children}
    </Box>
  );
});

export { Dialog };
