import React from "react";
import { Box, Flex } from "@chakra-ui/react";

const FolderTitle = ({
  backgroundColor = "saweria-pink.normal",
  children,
  ...props
}) => {
  return (
    <Box
      border="default"
      borderBottom={0}
      paddingX={2}
      paddingY={2}
      roundedTopLeft="md"
      roundedTopRight="md"
      backgroundColor={backgroundColor}
      {...props}
    >
      {children}
    </Box>
  );
};
const Folder = ({ title, backgroundColor = "whitish", children, ...props }) => {
  return (
    <Box {...props} boxShadow="raised" borderRadius="md">
      <Flex w={"100%"} direction="row" justifyContent="flex-end">
        {title()}
      </Flex>

      <Box
        paddingX="3em"
        paddingY="5"
        border="default"
        roundedBottom="md"
        roundedTopLeft="md"
        backgroundColor={backgroundColor}
      >
        {children}
      </Box>
    </Box>
  );
};

export { Folder, FolderTitle };
