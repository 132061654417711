import { Box, Link } from "@chakra-ui/react";
import Marquee from "react-fast-marquee";
import { t } from "@lingui/macro";

const showAnnouncement = true;

const Announcement = () => {
  if (!showAnnouncement) {
    return null;
  }

  return (
    <Box w="100vw" bgColor="#faae2b">
      <Link href="https://saweria.co/overlays" >
        <Marquee gradient={false} speed={100}>
          <Box w="max-content" ml={5}>
          {t`Sekarang Media share bisa menggunakan TikTok dan IG Reels! Silahkan Cek di pengaturan overlay.`}
          </Box>
        </Marquee>
      </Link>
   </Box>
  );
};

export { Announcement };
