import React from "react";
import { ButtonGroup, Flex, IconButton } from "@chakra-ui/react";
import Link from "./Link";
import { FaDiscord, FaInstagram, FaTwitter } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { Trans } from "@lingui/macro";

const Footer = (props) => {
  return (
    <Flex
      fontSize={["sm", "m", "lg"]}
      direction="row"
      justifyContent="space-between"
      {...props}
    >
      <Flex
        wrap="wrap"
        direction="column"
        justifyContent="space-evenly"
        marginBottom={3}
      >
        <span>Made with 💙 from SUB </span>
        <span>PT Harta Tahta Sukaria</span>
      </Flex>
      <Flex
        wrap="wrap"
        direction="column"
        marginBottom={2}
        textAlign="right"
        justifyContent="space-between"
      >
        <Link textDecoration="underline" href="/terms">
          <Trans>Syarat dan ketentuan</Trans>
        </Link>
        <Link textDecoration="underline" href="/faq">
          FAQ
        </Link>

        <Link textDecoration="underline" href="/docs/changelog">
          Changelog
        </Link>
        <Flex justify={["center", "right"]} mb={3}>
          <ButtonGroup spacing={0}>
            <IconButton
              as="a"
              target="_blank"
              href={"mailto:help@saweria.co"}
              isRound
              size="md"
              variant="ghost"
              colorScheme="gray"
              fontSize="25px"
              icon={<AiOutlineMail />}
            />
            <IconButton
              as="a"
              target="_blank"
              href={"https://instagram.com/saweria_co"}
              isRound
              size="md"
              variant="ghost"
              colorScheme="gray"
              fontSize="25px"
              icon={<FaInstagram />}
            />
            <IconButton
              as="a"
              target="_blank"
              href={"https://twitter.com/saweriaco"}
              isRound
              size="md"
              variant="ghost"
              colorScheme="gray"
              fontSize="25px"
              icon={<FaTwitter />}
            />
            <IconButton
              as="a"
              target="_blank"
              href="https://discord.com/invite/N85rW8derq"
              isRound
              size="md"
              variant="ghost"
              colorScheme="gray"
              fontSize="25px"
              icon={<FaDiscord />}
            />
          </ButtonGroup>
        </Flex>
      </Flex>
    </Flex>
  );
};

export { Footer };
