import React from "react";
import { Heading, Text } from "@chakra-ui/react";

const P = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <Text ref={ref} marginBottom={3} fontSize={["sm", "sm", "md"]} {...props}>
      {children}
    </Text>
  );
});

const Large = React.forwardRef(({ className, children, ...props }, ref) => {
  return (
    <Heading
      ref={ref}
      as="h3"
      fontSize={["xl", "2xl", "3xl"]}
      color="black"
      marginBottom={3}
      textTransform="none"
      fontWeight="800"
      {...props}
    >
      {children}
    </Heading>
  );
});

export { P, Large };
